<template>
  <div class="zxfwlist">
    <div class="header">
      <div class="h1">中介服务</div>
      <div class="h2">全面・便捷・专业 助力企业成长</div>
    </div>
    <div>
    </div>
    <div class="content">
      <div class="zhuti">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
          <el-breadcrumb-item  :to="{ path: '/zxfw' }">中介服务</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/zxfwlist' }">机构列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="zhuti"  style="background: #fff;margin-top: 35px">
          <avue-search :option="option"   @change="loadlist(true)"
                       v-model="form">
            <template #name="{}">
              <el-col :span="6">
                <avue-input v-model="form1.name" placeholder="请输入" ></avue-input>
              </el-col>
            </template>
          </avue-search>
          <div style="text-align: right;padding: 0 20px 20px 0">
            <el-button type="primary" size="mini" @click="loadlist(true)">筛选</el-button>
            <el-button  size="mini" @click="reset">重置</el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="content1">
        <div></div>
        <div class="flex lists" @click="goxq(list)" v-for="(list,index) in arr" :key="index">
          <div class="img"><el-image :src="list.image"></el-image></div>
          <div class="flex-1">
            <div class="title">{{list.name}}</div>
            <div class="neirong">服务类型: {{list.typeName}}</div>
            <div class="ellipsis3" style="margin-top: 10px">简介：{{list.subTitle}}</div>
          </div>
          <div>
            <el-button type="danger" class="btn" @click.stop="gotolink(list)">在线咨询</el-button>
          </div>
        </div>
        <div class="fenye">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              layout="total, sizes, prev, pager, next, jumper"
              :current-page="page.current"
              :page-sizes="[10, 50, 100, 200]"
              :page-size="page.size"
              :total="page.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import formlist from "../../components/formlist";
import {indexpublic} from "../../request/moudle";
import {intermediaryindex, intermediarylistindex, intermediarypage} from "../../request/moudle/zxfw";
import {fiveyears} from "../../utils";

export default {
  name: "gonggaoxq",
  components:{
    formlist
  },
  data(){
    return  {
      form:{
        categoryId:this.$route.query.typeId
      },
      form1:{
        name:'',
      },
      page:{
        current:1,
        size:10,
        total:0
      },
      keyword:'',
      option: {
        column: [ {
          label: '标题',
          prop: 'name',
        },{
          label: '类型',
          prop: 'categoryId',
          props: {
            label: 'title',
            value: 'id',
          },
          multiple: false,
          dicData:[],
          datasInfo: '',
        }]
      },
      arr:[]
    }
  },
  created() {
    this.loadconfig()
    this.loadlist()
    this.getUrlLink()
  },
  methods:{
    loadconfig(){
      this.$api.zxfw.intermediarylistindex().then((res)=>{
        const typeId=this.findObject(this.option.column, 'categoryId')
        typeId.dicData=res.data.categoryList
        typeId.dicData.unshift({ title: '不限', id: '' })
      })
    },
    getUrlLink(){
      this.$api.index.indexpublic().then((res)=>{
          this.datasInfo=res.data;
      })
    },
    gotolink(item){
      if(item.link){
        window.open(item.link,'_blank')
      }
      //window.open(this.datasInfo.consultUrl)
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={
        ...this.form,
        ...this.form1
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.zxfw.intermediarypage(obj).then((res)=>{
        this.arr=res.data.records
        this.page.total=res.data.total
      })
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
    reset(){
      for (const val of this.option.column){
        if(val.multiple){
          this.form[val.prop]=['']
        }else {
          this.form[val.prop]=''
        }
      }
      this.form1.name=''
    },
    goxq(item){
      this.$router.push({
        path:'/zxfwxq',
        query:{
          id:item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.fenye{
  text-align: center;
  padding: 30px 0;
}
.zhuti{
  width: 1200px ;
  margin: 0px auto;
  padding: 20px 0;
}
  .zxfwlist{
     background: #F0F5FE;
    .header{
      height: 180px;
      background: url("../../assets/banner/zxpx.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
      text-align: center;
      color: #fff;
      .h1 {
        font-size: 42px;
        font-weight: 500;
      }

      .h2 {
        font-size: 22px;
        line-height: 80px;
      }
    }
    .content{
      background: #fff;
    }
    .content1{
      width: 1200px;
      margin: 0 auto;
      margin-top: 15px;
      .search{
        margin: 40px 0;
        text-align: right;
        border-top: 1px solid #eeeeee;
        padding-top: 20px;
      }
      .lists{
        border-bottom: 1px solid #eeeeee;
        padding: 20px ;
        cursor: pointer;
        background: #fff;
        border-radius: 6px;
        margin-bottom: 15px;
        .btn{
          margin-top: 55px;
          margin-left: 40px;
        }
        .img{
          width: 200px;
          height: 130px;
          background: #eeeeee;
          margin-right: 25px;
        }
        .title{
          font-size: 18px;
          font-weight: bolder;
          line-height: 50px;
        }
        .neirong{
          color: #292929;
          line-height: 16px;
        }
      }
    }
  }
</style>